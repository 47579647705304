import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render} from 'react-dom'
// import OLMapFragment from './App';
// import AppTest from './App.test';
// import Header from './Home';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Strict Mode cause render to run twice
// Currently strict mode was set false in tsconfig.json
// root.render(
//   <React.StrictMode>
//     {/* <App /> */}
//     {/* <Header/> */}
//     <OLMapFragment />
//   </React.StrictMode>
  
// );

root.render(
  <App></App>
  // <LegendLayer></LegendLayer>
 );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
