import React from 'react';
import { Component, useState  } from 'react';
// import 'ol/ol.css';
import './App.css';

// Start Openlayers imports
import { Map, View} from 'ol'
import { GeoJSON } from 'ol/format'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, OSM as OSMSource, TileWMS as TileWMSSource } from 'ol/source'
import { Select as SelectInteraction, defaults as DefaultInteractions } from 'ol/interaction'
import { Attribution, ScaleLine, ZoomSlider, Zoom, Rotate, MousePosition, OverviewMap, defaults as DefaultControls} from 'ol/control'
import {Style,Fill as FillStyle, RegularShape as RegularShapeStyle, Stroke as StrokeStyle} from 'ol/style'
import { Projection,get as getProjection} from 'ol/proj'

import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import {toLonLat} from 'ol/proj.js';
import {toStringHDMS} from 'ol/coordinate.js';


import MapComponent from './Map/MapComponent';
import { BrowserRouter, Router, Route, Routes, Link } from 'react-router-dom';
import AdvanceSearch from './Search/AdvanceSearch';

function App() {

  console.log("App component")
  return (
    
    <div>
   <BrowserRouter>
   <Routes>
    <Route path="/AdvanceSearch" element={<AdvanceSearch />} />
  </Routes>
  </BrowserRouter>  

  <MapComponent/>
    </div>

      
    )

    

}



export default App